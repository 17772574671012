import React from "react";
import { Link } from "react-router-dom";
import LOGO from "../img/logo.svg";

const PaymentSuccessfulPage = () => {
  return (
    <Holder>
      <div
        style={{
          padding: "10px",
          margin: "10px",
          backgroundColor: "white",
          color: "#111",
        }}
      >
        <div>
          <h2>Welcome Back!!</h2>
          <p>
            Your payment was successful and your plan has been renewed.
            <br />
            For enquiries, please don't hesitate to send a mail to{" "}
            <a
              href={`mailto: ${process.env.REACT_APP_CONTACT_EMAIL}?Subject=Enrollee Enquiry [from web app]`}
            >
              {" "}
              {process.env.REACT_APP_CONTACT_EMAIL}
            </a>
            <br />
            Thanks for choosing RelianceHMO.
          </p>
          <br />
          <br />
          <div style={{ fontSize: "22px" }}>
            <div style={{ float: "left" }}>
              <Link to="/">Continue</Link>
            </div>
          </div>
        </div>
      </div>
    </Holder>
  );
};

export const Holder = (props) => (
  <div className="container">
    <div className="signup">
      <div className="signup__header">
        <img src={LOGO} alt="logo" />
        <p>&nbsp;| Enrollee Sign-Up Form</p>
      </div>
      <div className="signup__form">{props.children}</div>
    </div>
  </div>
);

export default PaymentSuccessfulPage;
