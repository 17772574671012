import React from "react";
import { sessionService } from "redux-react-session";
import moment from "moment";
import userService from "../services/userService";
import LoadingScreen from "../components/LoadingScreen";
import { getAccountsDashboardUrl } from "../services/utilityService";
import { Link } from "react-router-dom";
import CountdownTimer from "./CountdownTimer";
import PageBodyHeader from "./boxes/PageBodyHeader";

class PlanExpiredPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      error: {},
    };
  }

  componentDidMount() {
    sessionService.loadUser().then((user) => {
      userService.getPlanDetails().then((plan) => {
        user.plan = plan.product.name;
        user.planType = plan.product.plan_type;
        user.planInfo = plan;
        sessionService.saveUser(user).then(() => {
          //check if plan has been renewed...and go back to app
          if (
            user.planInfo.active_status_id !== 4 /*Suspended*/ &&
            user.planInfo.active_status_id !== 5 /*Expired*/ &&
            user.planInfo.active_status_id !== 7 /*Delayed*/
          ) {
            window.location = "/";
          }

          this.setState({
            user: user,
          });
        });
      });
    });
  }

  goToAccounts = () => {
    const refCode = this.state.user.planInfo.invoice_reference_code;
    window.location = `${getAccountsDashboardUrl()}/dashboard/invoices/${refCode}`;
  };

  renderLoading = () => {
    return <LoadingScreen />;
  };

  render() {
    const { user, error } = this.state;

    if (!user.planInfo) return this.renderLoading();

    const countDown = moment(user.planInfo.cover_start_date)
      .add(7, "d")
      .format();

    return (
      <div className="container expired-plan-container">
        <PageBodyHeader />
        <div className="content">
          <div className="content-image">
            {error.message && (
              <div
                ref={(el) => (this.errorHolder = el)}
                style={{
                  padding: "10px",
                  margin: "10px",
                  backgroundColor: "red",
                  color: "white",
                  visibility: error.message ? "visible" : "hidden",
                }}
              >
                <h3>{error.message}</h3>
              </div>
            )}
          </div>
          <div className="content-main">
            {user.planInfo.active_status_id === 7 ? (
              <div>
                <h1 className="plan-expiry-notice__header-text">Thank you!</h1>
                <p className="plan-expiry-notice__body-text">
                  We applaud you for doing the right thing for your mind and
                  body. You have successfully resubscribed.
                </p>
                <p className="plan-expiry-notice__body-text">
                  However, you still have to wait 7 days before you can access
                  care.
                </p>
                <p className="plan-expiry-notice__body-text">
                  You’ll be notified when the dashboard is available.
                </p>
                <p className="plan-expiry-notice__body-text">
                  If you have any questions, please contact us via{" "}
                  {process.env.REACT_APP_CONTACT_EMAIL} or call 0700-7354-2623.
                </p>
                <CountdownTimer date={countDown} />
              </div>
            ) : (
              <div
                style={{ alignSelf: "center", margin: "5px auto" }}
                className="dashboard-card dashboard-card--pb-large"
              >
                <div className="expiry-notice-wrapper">
                  <div className="dashboard-card__alert dashboard-card__alert--blue dashboard-card__body-text">
                    <p className="article__text article__text--bold">
                      Plan expired.
                    </p>
                  </div>
                  <p className="dashboard-card__body-text">
                    You did not renew your subscription and now your plan has
                    been deactivated.
                  </p>
                  {user.planInfo.is_owner ? (
                    <>
                      <p className="dashboard-card__body-text">
                        Would you like to reactivate your plan?
                      </p>
                      <button
                        className="dashboard__primary-btn"
                        onClick={this.goToAccounts}
                      >
                        <p>Reactivate Plan</p>
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="dashboard-card__body-text">
                        Please contact your account manager to renew your plan.
                      </p>
                    </>
                  )}
                  <a href="/logout" className="dashboard__secondary-btn">
                    Logout
                  </a>
                </div>
              </div>
            )}

            <p>
              You can access your referrals and earnings by clicking{" "}
              <Link to="/refer">here</Link>
            </p>

            <p>
              Please contact support at{" "}
              <a href="mailto: {process.env.REACT_APP_CONTACT_EMAIL}?subject=Plan Expired">
                {" "}
                {process.env.REACT_APP_CONTACT_EMAIL}
              </a>
              or call <a href="tel:070073542623">070073542623</a> for enquires.{" "}
              <br />
              Apologies for any inconvenience.
            </p>
            <p>
              Click <a href="/logout">here to logout</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanExpiredPage;
