import React from "react";
import moment from "moment";
import userService from "../services/userService";
import SecondaryCareRequestForm from "../components/forms/SecondaryCareRequestForm";
import LoadingScreen from "../components/LoadingScreen";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import Modal from "../components/boxes/Modal";
import { useTranslation, withTranslation } from "react-i18next";

class SecondaryCareContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      availableCare: [],
      selectedSecondaryCare: null,
      showRequestCareModal: false,
      showRequestResultModal: false,
      secondaryCareRequestResult: null,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    userService.getSecondaryCareBenefits().then((benefits) => {
      this.setState({
        isLoading: false,
        availableCare: benefits,
      });
    });
  }

  toggleRequestModal = () => {
    this.setState({
      showRequestCareModal: !this.state.showRequestCareModal,
    });
  };

  setSelectedSecondaryCare = (careId, careName, typeId) => {
    this.toggleRequestModal();
    this.setState({
      selectedSecondaryCare: {
        careId,
        careName,
        typeId,
      },
    });
  };

  requestSecondaryCare = (values) => {
    if (values.provider_id && Number(values.provider_id) !== -1) {
      this.setState({
        isLoading: true,
        showRequestCareModal: false,
      });
      userService
        .requestSecondaryCare(
          this.state.selectedSecondaryCare.careId,
          values.provider_id,
          values.date_time
        )
        .then((result) => {
          this.setState({
            isLoading: false,
            secondaryCareRequestResult: result,
            showRequestResultModal: true,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            secondaryCareRequestResult: {
              message: err.response.data.message,
              paCode: null,
            },
            showRequestResultModal: true,
          });
        });
    }
  };

  hideRequestResultModal = () => {
    this.setState({
      showRequestResultModal: false,
      secondaryCareRequestResult: null,
    });
  };

  careUsedTranslated = (careUsed) => {
    const { t } = this.props;

    switch (careUsed) {
      case 0:
        return t("general.0");
      case 1:
        return t("general.1");
      case 2:
        return t("general.2");
      case 3:
        return t("general.3");
      default:
        return careUsed;
    }
  };

  render() {
    const { user } = this.props;
    const {
      isLoading,
      availableCare,
      showRequestCareModal,
      selectedSecondaryCare,
      showRequestResultModal,
      secondaryCareRequestResult,
    } = this.state;

    if (isLoading) {
      return (
        <MainContent>
          <PageHeader
            title={this.props.t("my_benefits.access_secondary_care")}
          />
          <PageBody>
            <LoadingScreen />
          </PageBody>
        </MainContent>
      );
    }

    return (
      <MainContent>
        <PageHeader title={this.props.t("my_benefits.access_secondary_care")} />
        <PageBody>
          <React.Fragment>
            {showRequestCareModal && (
              <SecondaryCareRequestModal
                accessToken={user.credentials.accessToken}
                careName={selectedSecondaryCare.careName}
                typeId={selectedSecondaryCare.typeId}
                onRequest={this.requestSecondaryCare}
                onClose={this.toggleRequestModal}
              />
            )}

            {showRequestResultModal && (
              <RequestResultModal
                careName={selectedSecondaryCare.careName}
                result={secondaryCareRequestResult}
                onClose={() => window.location.reload(true)}
              />
            )}
            <div className="dashboard-card secondary-care-contact">
              <h2>
                {`${this.props.t("my_benefits.other_secondary_care")} `}
                <a href={`mailto: ${process.env.REACT_APP_CONTACT_EMAIL}`}>
                  {process.env.REACT_APP_CONTACT_EMAIL}
                </a>{" "}
                {`${this.props.t("general.or")} `}
                <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE_NUMBER}`}>
                  {" "}
                  {process.env.REACT_APP_CONTACT_PHONE_NUMBER}
                </a>
              </h2>
            </div>

            <div className="sc-card-wrap">
              {availableCare && availableCare.length > 0 ? (
                availableCare.map((care) => (
                  <div
                    className="dashboard-card sc-card"
                    key={care.id}
                    onClick={() => {
                      if (
                        care.is_accessible &&
                        parseInt(care.limit[0]) > 0 &&
                        care.used < parseInt(care.limit[0])
                      )
                        this.setSelectedSecondaryCare(
                          care.id,
                          care.name,
                          care.provider_type_id
                        );
                    }}
                  >
                    <i
                      className={`fas fa-5x ${
                        care.name === "Spa" ? "fa-spa" : "fa-dumbbell"
                      }${
                        care.used === care.limit ||
                        care.limit === 0 ||
                        !care.is_accessible
                          ? " disabled"
                          : ""
                      }`}
                    ></i>
                    <h2 className="sc-card__title-text">
                      {this.props.t(`my_benefits.${care.name.toLowerCase()}`)}
                    </h2>

                    {care.is_accessible &&
                    care.used < parseInt(care.limit[0]) ? (
                      <React.Fragment>
                        <p className="sc-card__text">
                          {this.props.t("my_benefits.spa_text", {
                            limit: care.limit,
                            frequency: care.frequency,
                            interpolation: { escapeValue: false },
                          })}
                        </p>
                        <br></br>
                        <p className="sc-card__text">
                          {this.props.t("my_benefits.use", {
                            count: this.careUsedTranslated(care.used),
                          })}
                          .
                        </p>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {care.used >= care.limit ? (
                          <p className="sc-card__text">
                            {this.props.t("my_benefits.slot_used_up")}
                          </p>
                        ) : (
                          <p className="sc-card__text">
                            {this.props.t("my_benefits.not_available")}
                          </p>
                        )}
                        <p className="sc-card__text"> ... </p>
                      </React.Fragment>
                    )}
                  </div>
                ))
              ) : (
                <div style={{ marginTop: "5rem" }}>
                  <p
                    style={{
                      fontSize: "2.5rem",
                      color: "#ccc",
                      fontWeight: "bolder",
                    }}
                  >
                    {this.props.t("my_benefits.no_secondary_care_available")}
                  </p>
                </div>
              )}
            </div>
          </React.Fragment>
        </PageBody>
      </MainContent>
    );
  }
}

const SecondaryCareRequestModal = ({
  accessToken,
  typeId,
  careName,
  onRequest,
  onClose,
}) => {
  const { t } = useTranslation(["webapp"]);

  return (
    <Modal
      title={t(`my_benefits.access_${careName.toLowerCase()}`)}
      onClose={onClose}
    >
      <SecondaryCareRequestForm
        accessToken={accessToken}
        typeId={typeId}
        careName={careName}
        onSubmit={onRequest}
      />
    </Modal>
  );
};

const RequestResultModal = ({ careName, result, onClose }) => {
  const { t, i18n } = useTranslation(["webapp"]);

  const locale = i18n.language === "en" ? "en-gb" : i18n.language;

  return (
    <Modal
      title={t(`my_benefits.access_${careName.toLowerCase()}`)}
      onClose={onClose}
    >
      <div className="pa-code-modal">
        {result.paCode ? (
          <div className="code_holder">
            <p className="message">{t("my_benefits.gym_success")}</p>
            <p>{t("my_benefits.code_below")}</p>
            <h2 className="code">{result.paCode}</h2>
            <div className="date">
              <b>
                {t("my_benefits.code_when", {
                  date: result.created_at
                    ? moment(result.created_at)
                        .locale(locale)
                        .format("DD MMM YYYY, h:mm a")
                    : moment().locale(locale).format("DD MMM YYYY, h:mm a"),
                })}
              </b>
              {result.expired_at && (
                <span>
                  {` ${t("my_benefits.code_expires")} ${moment(
                    result.expired_at
                  )
                    .locale(locale)
                    .format("DD MMM YYYY, h:mm a")}`}
                </span>
              )}
            </div>
            <p>{t("my_benefits.note_code")}</p>
          </div>
        ) : (
          <p className="spa-success-message message">
            {t("my_benefits.request_success")}
          </p>
        )}
        <hr />
        <p>
          {`${t("my_benefits.help_enquires")} `} <br />
          {`${t("my_benefits.help_desk")} `}
          <br />
          <a href={`mailto: ${process.env.REACT_APP_CONTACT_EMAIL}`}>
            {process.env.REACT_APP_CONTACT_EMAIL}
          </a>{" "}
          or{" "}
          <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE_NUMBER}`}>
            {process.env.REACT_APP_CONTACT_PHONE_NUMBER}
          </a>
        </p>
      </div>
    </Modal>
  );
};

const SecondaryCareContainerWithTranslation = withTranslation(["webapp"])(
  SecondaryCareContainer
);
export default SecondaryCareContainerWithTranslation;
