import React from "react";
import * as _ from "lodash";
import SidebarMenuItem from "../components/SidebarMenuItem";
import LOGO from "../img/RH_logo-white.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getAccountsDashboardUrl } from "../services/utilityService";
import { withTranslation } from "react-i18next";
import { isFeatureEnabled } from "@reliance/feature-flags";
import { features } from "../services/constants";
const items = [
  {
    path: "/",
    icon: "fas fa-tachometer-alt",
    title: "dashboard",
  },
  {
    path: "/consultations",
    icon: "fas fa-comment-alt",
    title: "Consult a Doctor",
  },
  isFeatureEnabled(features.healthTips) && {
    path: "/tips",
    icon: "fas fa-heartbeat",
    title: "Health Tips",
  },
  isFeatureEnabled(features.refer) && {
    path: "/refer",
    icon: "fas fa-people-arrows",
    badge: "New",
    title: "Refer",
  },

  {
    path: "/profile",
    icon: "fas fa-user-circle",
    title: "my_profile",
  },
  {
    path: `${getAccountsDashboardUrl()}/dashboard/enrollees`,
    icon: "fas fa-users",
    title: "Manage Enrolles",
  },
  {
    path: `${getAccountsDashboardUrl()}/dashboard/invoices/edit/new-invoice`,
    icon: "fas fa-plus",
    title: "Add Accounts",
  },
  {
    path: "/hospitals",
    icon: "fas fa-hospital-alt",
    title: "my_hospitals",
  },
  {
    path: "/benefits",
    icon: "fas fa-star",
    title: "my_benefits",
  },
  {
    path: "/settings",
    icon: "fas fa-cog",
    title: "Settings",
  },
].filter(Boolean); //Filters out any 'false' values

class Sidebar extends React.Component {
  getMessageStats(newMsgs, confirmations) {
    const newMsgConsultationIds = [];
    const endConsultationIds = Object.keys(confirmations);

    Object.keys(newMsgs).forEach((msgKey) => {
      if (
        !newMsgConsultationIds.includes(newMsgs[msgKey].consultation_id) &&
        newMsgs[msgKey].display
      ) {
        newMsgConsultationIds.push(newMsgs[msgKey].consultation_id);
      }
    });

    return _.union(newMsgConsultationIds, endConsultationIds);
  }

  render() {
    const { showSideBar, newMessages, endConsultationConfirmations, user } =
      this.props;
    const msgStats = this.getMessageStats(
      newMessages,
      endConsultationConfirmations
    );
    let filteredSideBarItems = items;

    const filteredItems = () => {
      if (user.sex.toLowerCase() === "m") {
        filteredSideBarItems = items.filter(
          (item) => item?.title?.toLowerCase() !== "cycle planner"
        );
      }

      if (user?.package?.toLowerCase() === "corporate") {
        filteredSideBarItems = items.filter(
          (item) =>
            item.title.toLowerCase() !== "manage enrolles" &&
            item.title.toLowerCase() !== "add accounts"
        );
      }
    };
    filteredItems();

    return (
      <div
        id="left"
        className={`column sidebar ${showSideBar ? "" : "active"}`}
      >
        <div className="top-left">
          <div className="sidebar__top">
            <img
              src={LOGO}
              alt="RelianceHMO's Logo"
              className="reliance-health-logo"
            />
          </div>
        </div>
        <div className="bottom sidebar--space-between sidebar--enrollee">
          <nav>
            <ul>
              {filteredSideBarItems.map((item, i) => {
                if (item.path === "/") {
                  return (
                    <SidebarMenuItem
                      dataTut="step_one_tour"
                      key={i}
                      to={item.path}
                      icon={item.icon}
                      newMessage={{
                        icon: "fas fa-envelope",
                        count: msgStats.length,
                      }}
                      title={this.props.t(`sidebar.${item.title}`)}
                    />
                  );
                }
                if (item.path === "/tips") {
                  return (
                    <SidebarMenuItem
                      dataTut="step_tips_tour"
                      key={i}
                      to={item.path}
                      icon={item.icon}
                      title={item.title}
                    />
                  );
                }
                if (item.path === "/consultations") {
                  return (
                    <SidebarMenuItem
                      dataTut="step_four_tour"
                      key={i}
                      to={item.path}
                      icon={item.icon}
                      title={this.props.t("sidebar.consult_doctor")}
                    />
                  );
                }
                // removed temporarily for Egypt
                // if (item.path === '/refer') {
                //     return (
                //         <SidebarMenuItem
                //             dataTut="step_refer_tour"
                //             key={i}
                //             to={item.path}
                //             icon={item.icon}
                //             title={item.title}
                //         />
                //     );
                // }
                if (item.path === "/profile") {
                  return (
                    <SidebarMenuItem
                      dataTut="step_profile_tour"
                      key={i}
                      to={item.path}
                      icon={item.icon}
                      title={this.props.t(`sidebar.${item.title}`)}
                    />
                  );
                }
                if (item.path === "/hospitals") {
                  return (
                    <SidebarMenuItem
                      dataTut="step_five_tour"
                      key={i}
                      to={item.path}
                      icon={item.icon}
                      title={this.props.t(`sidebar.${item.title}`)}
                    />
                  );
                }
                if (item.path === "/benefits") {
                  return (
                    <SidebarMenuItem
                      dataTut="step_six_tour"
                      key={i}
                      to={item.path}
                      icon={item.icon}
                      title={this.props.t(`sidebar.${item.title}`)}
                    />
                  );
                }
                if (item.path === "/settings") {
                  return (
                    <SidebarMenuItem
                      dataTut="step_settings_tour"
                      key={i}
                      to={item.path}
                      icon={item.icon}
                      title={this.props.t("sidebar.settings")}
                    />
                  );
                }
                return (
                  <SidebarMenuItem
                    key={i}
                    to={item.path}
                    icon={item.icon}
                    badge={item.badge}
                    title={item.title}
                  />
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ display, consultation }) {
  const { showSideBar } = display || {};
  return {
    showSideBar,
    newMessages: { ...consultation.newMessages },
    endConsultationConfirmations: {
      ...consultation.endConsultationConfirmations,
    },
  };
}

const SidebarWithTranslation = withTranslation(["webapp"])(Sidebar);
export default withRouter(connect(mapStateToProps)(SidebarWithTranslation));
