export function loadZohoSalesIQScript(user) {
  // Check if Zoho SalesIQ script is already loaded

  if (document.getElementById("zsiqscript")) {
    return; // The script is already loaded, no need to load it again
  }

  const widgetCode = process.env.REACT_APP_ZOHO_WIDGETCODE;
  const countryCode = process.env.REACT_APP_COUNTRY_LOCALE;
  const getCountryCode = () => {
    if (!countryCode) {
      return "NG";
    }
    return countryCode.split("-")[1];
  };

  // Create a new script element for Zoho SalesIQ
  var zohoScript = document.createElement("script");
  zohoScript.type = "text/javascript";
  zohoScript.id = "zsiqchat";
  zohoScript.defer = true;
  zohoScript.innerHTML = `
      var $zoho=$zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
    widgetcode: "${widgetCode}",
    values: {},
    ready: function () {}
    };
    var d=document;
    var s=d.createElement("script");
    s.type="text/javascript";
    s.id="zsiqscript";
    s.defer=true;
    s.src="https://salesiq.zohopublic.com/widget";
    var t=d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s,t);
    $zoho.salesiq.ready=function(){
      $zoho.salesiq.visitor.name("${user?.firstName + " " + user?.lastName}");
      $zoho.salesiq.visitor.email("${user?.emailAddress}");
      $zoho.salesiq.visitor.id("${user?.id}");
      $zoho.salesiq.visitor.info({"hmo_id" : "${user?.hmoId}","plan" : "${
    user?.plan
  }","source" : "Reliance Care (WEB APP) ${getCountryCode()}"});
    }
`;

  // Append the script element to the head section
  document.head.appendChild(zohoScript);
}
