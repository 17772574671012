import React, { useEffect, useState } from "react";
import { MESSAGE_TYPE, ATTACHMENT_TYPE } from "../../services/utilityService";
import CallData from "./CallData";
import MediaData from "./MediaData";
import TextData from "./TextData";
import ReferralData from "./ReferralData";
import PrescriptionData from "./PrescriptionData";
import { ENDPOINTS } from "../../services/ApiService";
import { MODALS } from "../utils/ConsultationModals";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updatePrescriptionDetail } from "../../actions/consultation";
import { isFeatureEnabled } from "@reliance/feature-flags";
import { features } from "../../services/constants";

const BubbleLeft = (props) => {
  const [prescriptions, setPresctiption] = useState(null);
  const getPrescriptionDetails = async () => {
    if (
      props.type === MESSAGE_TYPE.action &&
      props.attachments.length > 0 &&
      props.attachments[0].type === ATTACHMENT_TYPE.prescription &&
      props.attachments.length
    ) {
      if (props.state === "active") {
        try {
          const data = await props.service.get(
            ENDPOINTS.consultations.prescriptions(
              props.consultationId,
              props.attachments[0].content.id
            )
          );
          setPresctiption(data?.data);
          props.dispatch(
            updatePrescriptionDetail({
              ...data?.data,
              message: props.message,
              prescriptionMessage: props.prescriptionMessage,
            })
          );
        } catch (error) {
          console.info(error); // handle get prescription error
        }
      }
    }
  };

  useEffect(() => {
    getPrescriptionDetails();
    props.dispatch(updatePrescriptionDetail(prescriptions));
  }, [props.attachments.length]);
  return (
    <React.Fragment>
      <div className="doctor-chat">
        {props.type ? (
          props.type === MESSAGE_TYPE.action &&
          props.attachments?.length &&
          props.attachments[0].type === ATTACHMENT_TYPE.prescription ? (
            <>
              {displayMessageByType(
                { ...props, prescriptions, MESSAGE_TYPE, ATTACHMENT_TYPE },
                props.type
              )}
            </>
          ) : (
            <div className="chat-item__message doctor-chat__bubble">
              {displayMessageByType({ ...props, prescriptions }, props.type)}
            </div>
          )
        ) : (
          <div className="chat-item__message doctor-chat__bubble">
            <TextData {...props} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const displayMessageByType = (props, type) => {
  const showFufillmentModal = () => {
    props.togglePharmacyModal(MODALS.consultationFulfillment);
  };
  if (type === MESSAGE_TYPE.text) {
    return <TextData {...props} />;
  }
  if (type == MESSAGE_TYPE.action && !props.attachments.length) {
    return <TextData {...props} />;
  }
  if (type === MESSAGE_TYPE.call) {
    return <CallData {...props} />;
  }
  if (type === MESSAGE_TYPE.action) {
    if (
      props.attachments.length &&
      props.attachments[0].type === ATTACHMENT_TYPE.media
    ) {
      return <MediaData {...props} />;
    }
    // logic to show most recent prescription when there are more than one deleted and not active will be added here
    {
      if (
        !isFeatureEnabled(features.telemedicineAbuseBanner) &&
        props.attachments.length &&
        props.state !== "deleted" &&
        props.attachments[0].type === ATTACHMENT_TYPE.prescription
      ) {
        return (
          <>
            <PrescriptionData
              {...props}
              showFufillmentModal={showFufillmentModal}
            />
          </>
        );
      }
    }

    if (
      props.attachments.length &&
      props.attachments[0].type === ATTACHMENT_TYPE.referral
    ) {
      return <ReferralData {...props} />;
    }
  }
};
const mapStateToProps = (store) => {
  const { consultation } = store;
  return {};
};

export default withRouter(connect(mapStateToProps)(BubbleLeft));

// export default BubbleLeft;
